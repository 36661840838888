






























































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { bytesToString } from '@/util/bytes';
import Axios from 'axios';
import { Upload } from '@/types/state';

export default Vue.extend({
  computed: {
    ...mapGetters({
      uploads: 'upload/list',
    }),
    list(): Upload[] {
      return this.uploads.filter((u: Upload) => !u.hidden);
    },
  },
  methods: {
    bytesToString,
    async mock() {
      const total = Math.random() * 1000;

      this.$store.commit('upload/set', {
        id: await this.$store.dispatch('upload/getNextId'),
        file: {
          name: 'my-file-name.txt',
        },
        upload: new Promise((r) => r(null)),
        total,
        loaded: Math.min(total, Math.random() * 1000),
        cancel: (message?: string) => { console.log(message); },
        cancelToken: Axios.CancelToken.source(),
        routerTo: {
          name: 'uploads',
        },
      });
    },
  },
});
