var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('h1',{staticClass:"text-2xl font-semibold mb-3",domProps:{"textContent":_vm._s(_vm.$t('title'))}}),_c('p',{staticClass:"mb-6",domProps:{"textContent":_vm._s(_vm.$t('empty'))}}),_vm._l((_vm.list),function(upload,i){return _c('MediaComponent',{key:i,attrs:{"id":upload.id}},[_c('BaseLevel',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"level-item"},[_c('h2',{staticClass:"text-xl ",class:{
              'font-bold': ("#" + (upload.id)) === _vm.$route.hash,
              'font-medium': ("#" + (upload.id)) !== _vm.$route.hash,
            }},[(upload.routerTo)?_c('RouterLink',{attrs:{"to":upload.routerTo},domProps:{"textContent":_vm._s(upload.file.name)}}):_c('span',{domProps:{"textContent":_vm._s(upload.file.name)}})],1)]),_c('div',{staticClass:"level-item"},[_c('span',{domProps:{"textContent":_vm._s(_vm.bytesToString(upload.file.size))}})]),_c('div',{staticClass:"level-item"},[_c('span',{domProps:{"textContent":_vm._s(upload.file.type)}})])]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"level-item"},[(!upload.done && !upload.error)?_c('a',{staticClass:"delete is-small",attrs:{"role":"button","title":_vm.$t('common.cancel')},on:{"click":function($event){upload.cancel(_vm.$t('cancelled'))}}}):(upload.cancelled)?_c('span',{staticClass:"has-text-danger",domProps:{"textContent":_vm._s(_vm.$t('cancelled'))}}):(upload.error)?_c('span',{staticClass:"has-text-danger",domProps:{"textContent":_vm._s(upload.error.message || _vm.$t('error'))}}):(upload.done)?_c('span',{staticClass:"has-text-primary",domProps:{"textContent":_vm._s(_vm.$t('done'))}}):_vm._e()]),_c('div',{staticClass:"level-item"},[_c('BaseProgress',{staticClass:"min-w-2xs",class:{
              'is-link': !upload.cancelled && !upload.done && !upload.error,
              'is-primary': upload.done,
              'is-danger': upload.error || upload.cancelled,
            },attrs:{"value":upload.loaded,"max":upload.total}})],1)]},proxy:true}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }